import React, { useState } from 'react';
import Web3 from 'web3';

interface MintFormProps {
  web3: Web3 | null;
  accounts: string[];
  isConnected: boolean;
  onSubmit: (address: string, tokenId: string, uri: string) => void;
}

const MintForm: React.FC<MintFormProps> = ({ web3, accounts, isConnected, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    address: '',
    tokenId: '',
    uri: ''
  });
  const [transactionInProgress, setTransactionInProgress] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (transactionInProgress) {
      console.log('Transaction already in progress...');
      return;
    }
    if (!web3 || !isConnected) {
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    try {
      setTransactionInProgress(true);

      onSubmit(formValues.address, formValues.tokenId, formValues.uri);

      // Optionally, reset form values after successful minting
      setFormValues({
        address: '',
        tokenId: '',
        uri: ''
      });
    } catch (error) {
      console.error('Error minting token:', error);
    } finally {
      setTransactionInProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">
          Recipient Address
        </label>
        <input
          type="text"
          id="address"
          name="address"
          value={formValues.address}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="tokenId" className="block text-sm font-medium text-gray-700">
          Token ID
        </label>
        <input
          type="text"
          id="tokenId"
          name="tokenId"
          value={formValues.tokenId}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mb-6">
        <label htmlFor="uri" className="block text-sm font-medium text-gray-700">
          Token URI
        </label>
        <input
          type="text"
          id="uri"
          name="uri"
          value={formValues.uri}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="text-center">
        <button
          type="submit"
          className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${transactionInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={transactionInProgress}
        >
          {transactionInProgress ? 'Minting...' : 'Mint Token'}
        </button>
      </div>
    </form>
  );
};

export default MintForm;
