// src/components/Menu.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Menu.css';

const Menu: React.FC = () => (
  <nav>
    <ul>
      <li><Link to="/">Home</Link></li>
      <li><Link to="/mint">Mint</Link></li>
      <li><Link to="/admin">Admin</Link></li>
      <li><Link to="/contract-info">Info</Link></li>
      <li><Link to="/metadata">Metadata</Link></li>
    </ul>
  </nav>
);

export default Menu;
