import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import MyTokenArtifact from '../artifacts/contracts/ETHWhitepaper.sol/EthereumWhitepaperSignedByVitalikButerin.json'; 
import ContractInfoGrid from '../components/ContractInfoGrid'; 
import { contractAddress } from '../constants/contract';

const ContractInfo: React.FC = () => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState(false);

  const [saleFeePercentage, setSaleFeePercentage] = useState<String | null>("-");
  const [salePrice, setSalePrice] = useState<string>("-");
  const [salePriceWei, setSalePriceWei] = useState<string>("-");
  const [tokenId, setTokenId] = useState<String | null>("-");
  const [saleAllowed, setSaleAllowed] = useState<Boolean>(false);
  const [tokenURI, setTokenURI] = useState<String>("-");

  useEffect(() => {
    

    // loadWeb3();
  }, []);

  const loadWeb3 = async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      const ethereum = window.ethereum;
      try {
        // Request account access
        await ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(ethereum);
        setWeb3(web3Instance);

        // Get accounts
        const accs = await web3Instance.eth.getAccounts();
        setAccounts(accs);
        setIsConnected(true);
        console.log('Connected to MetaMask. Accounts:', accs);

        await getTokenId(web3Instance, accs[0]);
        await getSaleFeePercentage(web3Instance, accs[0]);
        await getSalePrice(web3Instance, accs[0]);
        await getSaleAllowed(web3Instance, accs[0]);
        await getTokenURI(web3Instance, accs[0]);
        
      } catch (error) {
        console.error('Access denied to accounts:', error);
      }
    }
    // Legacy dapp browsers...
    else if ((window as any).web3) {
      const web3Instance = new Web3((window as any).web3.currentProvider);
      setWeb3(web3Instance);

      // Get accounts
      const accs = await web3Instance.eth.getAccounts();
      setAccounts(accs);
      setIsConnected(true);

      console.log('Connected to MetaMask (Legacy). Accounts:', accs);
      await getTokenId(web3Instance, accs[0]);
      await getSaleFeePercentage(web3Instance, accs[0]);
      await getSalePrice(web3Instance, accs[0]);
      await getSaleAllowed(web3Instance, accs[0]);
      await getTokenURI(web3Instance, accs[0]);
    }
    // Non-dapp browsers...
    else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };

  const getTokenURI = async (web3Instance: Web3, account: string) => {
    console.log('getting token URI: '); 
    try {
      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);

      //getting token id
      const tokenId: string | bigint = await nftContract.methods.getTokenId().call({ from: account });
      
      const tx: string | bigint = await nftContract.methods.tokenURI(tokenId.toString()).call({ from: account });

      setTokenURI(tx.toString());

    } catch (error) {
      alert('Error : ' + error);
      console.error('Error :', error);
    }
  };

  const getTokenId = async (web3Instance: Web3, account: string) => {
    console.log('getting token id: '); 
    try {
      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);
      
      const tx: string | bigint = await nftContract.methods.getTokenId().call({ from: account });

      setTokenId(tx.toString());

    } catch (error) {
      alert('Error : ' + error);
      console.error('Error :', error);
    }
  };

  const getSaleAllowed = async (web3Instance: Web3, account: string) => {
    console.log('getting sale allowed: '); 
    try {
      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);
      
      const tx = await nftContract.methods.getSaleAllowed().call({ from: account});

      console.log('sale allowed:');
      console.log(tx);
      // setSaleAllowed(tx);

      if(typeof(tx) == "boolean"){
        setSaleAllowed(tx);
      }

    } catch (error) {
      // alert('Error : ' + error);
      console.error('Error :', error);
    }
  };

  const getSalePrice = async (web3Instance: Web3, account: string) => {
    console.log('getting sale price: '); 
    try {
      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);
      
      const priceInWei: string | bigint = await nftContract.methods.getSalePrice().call({ from: account });

      // setSalePrice(tx.toString());

      setSalePriceWei(priceInWei.toString());

      const priceInEth = web3Instance.utils.fromWei(priceInWei.toString(), 'ether');

      const formattedPriceInEth = priceInEth.split('.').join('');

      console.log("formattedPriceInEth");
      console.log(formattedPriceInEth);

      setSalePrice(formattedPriceInEth);

    } catch (error) {
      // alert('Error : ' + error);
      console.error('Error :', error);
      const salePrice = "Unavailable";
      setSalePrice(salePrice);
    }
  };

  const getSaleFeePercentage = async (web3Instance: Web3, account: string) => {
    console.log('Getting sale fee percentage:');
    try {
      const contractABI = MyTokenArtifact.abi;
      
      const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);
  
      const saleFeeBasisPoints: string | bigint = await nftContract.methods.getSaleFeePercentage().call({ from: account });
  
      // Convert basis points to percentage
      let saleFeePercentage: string;
      if (typeof saleFeeBasisPoints === 'bigint') {
        saleFeePercentage = (Number(saleFeeBasisPoints) / 100).toFixed(2);
      } else {
        saleFeePercentage = (parseFloat(saleFeeBasisPoints) / 100).toFixed(2);
      }
  
      setSaleFeePercentage(saleFeePercentage + "%");
  
    } catch (error) {
      alert('Error getting sale fee percentage: ' + error);
      console.error('Error getting sale fee percentage:', error);
    }
  };

  const onPurchase = async () =>{
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log("Purchasing...");

    try{
      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3.eth.Contract(contractABI, contractAddress);
  
      const tx = await nftContract.methods.purchase(tokenId).send({
        from: accounts[0],
        value: salePriceWei
      });

      console.log(tx);
      console.log("Success!"); 
      alert("Transaction Completed Successfully"); 
    }catch(error){
      console.log(error);    
      alert(error);   
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-full w-[60%] p-6 bg-white rounded-lg shadow-md">
        
        <h1 className="text-3xl font-semibold text-center mb-6">Contract Information</h1>
        {isConnected ? (
          <ContractInfoGrid saleFeePercentage={saleFeePercentage} salePrice={salePrice} tokenId={tokenId} contractAddress={contractAddress} saleAllowed={saleAllowed} onPurchase={onPurchase} tokenURI={tokenURI} />
        ) : (
          <div className="text-center">
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={loadWeb3}
            >
              Connect to MetaMask
            </button>
          </div>
        )}
      </div>
    </div>
  );

};

export default ContractInfo;