import React from 'react';

const AnimationPage = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src={`${process.env.PUBLIC_URL}/animation.html`}
        style={{ height: '100%', width: '100%', border: 'none' }}
        title="Animation Page"
      />
    </div>
  );
};

export default AnimationPage;
