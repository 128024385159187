// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './components/Menu';
import Home from './pages/Home';
import Mint from './pages/Mint';
import Admin from './pages/Admin';
import ContractInfo from './pages/ContractInfo';
import Metadata from "./pages/Metadata";
import './App.css';
import 'tailwindcss/tailwind.css';


const App: React.FC = () => (
  <Router>
    <div>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/contract-info" element={<ContractInfo />} />
        <Route path="/metadata" element={<Metadata />} />
      </Routes>
    </div>
  </Router>
);

export default App;

