import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import MyTokenArtifact from '../artifacts/contracts/ETHWhitepaper.sol/EthereumWhitepaperSignedByVitalikButerin.json'; 
import MintForm from '../components/MintForm'; 
import {ethers} from "ethers";
import { contractAddress } from '../constants/contract';

const Mint: React.FC = () => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [formValues, setFormValues] = useState({
    address: '',
    tokenId: '',
    uri: ''
  });
  const [isConnected, setIsConnected] = useState(false);
  const [transactionInProgress, setTransactionInProgress] = useState(false);

  useEffect(() => {
  }, []);

  const loadWeb3 = async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      const ethereum = window.ethereum;
      try {
        // Request account access
        await ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(ethereum);
        setWeb3(web3Instance);

        // Get accounts
        const accs = await web3Instance.eth.getAccounts();
        setAccounts(accs);
        setIsConnected(true);

        console.log('Connected to MetaMask. Accounts:', accs);
      } catch (error) {
        console.error('Access denied to accounts:', error);
      }
    }
    // Legacy dapp browsers...
    else if ((window as any).web3) {
      const web3Instance = new Web3((window as any).web3.currentProvider);
      setWeb3(web3Instance);

      // Get accounts
      const accs = await web3Instance.eth.getAccounts();
      setAccounts(accs);
      setIsConnected(true);

      console.log('Connected to MetaMask (Legacy). Accounts:', accs);
    }
    // Non-dapp browsers...
    else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };

  const connectMetaMask = async () => {
    if (!web3) {
      console.error('Web3 instance not initialized.');
      return;
    }
    try {
      await web3.eth.requestAccounts();
      const accs = await web3.eth.getAccounts();
      setAccounts(accs);
      setIsConnected(true);

      console.log('Connected to MetaMask. Accounts:', accs);
    } catch (error) {
      console.error('Access denied to accounts:', error);
    }
  };

  const handleMintSubmit = async (address: string, tokenId: string, uri: string) => {
    // Ensure web3 is initialized and connected
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    try {
      const currentAddress = await getCurrentAddress();

      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
      const tx = await nftContract.methods.safeMint(address, tokenId, uri).send({ from: currentAddress });

      console.log(tx);

      // Optionally, reset form values after successful minting
      setFormValues({
        address: '',
        tokenId: '',
        uri: ''
      });

      alert("Token minted successfully")
      console.log('Token minted successfully!');

      // Optionally, reset form values after successful minting
    } catch (error) {
      alert('Error minting token: ' + error);
      console.error('Error minting token:', error);
    }
  };

  const getCurrentAddress = async (): Promise<string | undefined> => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        return accounts.length > 0 ? accounts[0] : undefined;
      } catch (error) {
        console.error('Error fetching accounts:', error);
        return undefined;
      }
    } else {
      console.log('MetaMask is not installed!');
      return undefined;
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-lg w-full p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-center mb-6">Token Minting Form</h1>
        {isConnected ? (
          <MintForm web3={web3} accounts={accounts} isConnected={isConnected} onSubmit={handleMintSubmit} />
        ) : (
          <div className="text-center">
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={loadWeb3}
            >
              Connect to MetaMask
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mint;
