import React from 'react';

interface Attribute {
  trait_type: string;
  value: string;
}

interface MetadataProps {
  name: string;
  description: string;
  image: string;
  animationUrl: string;
  attributes: Attribute[];
  creator: string;
  provenance: string;
}

const MetadataGrid: React.FC<MetadataProps> = ({ name, description, image, animationUrl, attributes, creator, provenance }) => {
  return (
    <div className="p-6 bg-white shadow-md rounded-md">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-1 md:col-span-2 text-center mb-6">
          <h1 className="text-2xl font-bold mb-2">{name}</h1>
          <p className="text-gray-700">{description}</p>
        </div>
        {/* <div className="col-span-1 flex justify-center">
          <img src={`https://gateway.pinata.cloud/ipfs/${image.split("ipfs://")[1]}`} alt={name} className="max-w-full h-auto rounded-md" />
        </div>
        <div className="col-span-1 flex justify-center">
          <a href={`https://gateway.pinata.cloud/ipfs/${animationUrl.split("ipfs://")[1]}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            View Animation
          </a>
        </div> */}
        <div className="col-span-1 md:col-span-2">
          <h2 className="text-xl font-bold mt-4 mb-2">Attributes</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {attributes.map((attribute, index) => (
              <div key={index} className="p-4 bg-gray-100 border border-gray-300 rounded-md">
                <span className="font-bold">{attribute.trait_type}:</span> {attribute.value}
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1 md:col-span-2 mt-4">
          <h2 className="text-xl font-bold mb-2">Creator</h2>
          <p>{creator}</p>
        </div>
        <div className="col-span-1 md:col-span-2 mt-4">
          <h2 className="text-xl font-bold mb-2">Provenance</h2>
          <a href={provenance} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            View Provenance
          </a>
        </div>
      </div>
    </div>
  );
};

export default MetadataGrid;
