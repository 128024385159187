// src/pages/Admin.tsx
import React, { useState, useEffect } from 'react';
import { pinataKey } from '../constants/keys';
import AdminForm from '../components/AdminForm';
import Web3 from 'web3';
import axios from 'axios';
import MyTokenArtifact from '../artifacts/contracts/ETHWhitepaper.sol/EthereumWhitepaperSignedByVitalikButerin.json'; 
import ContractInfoGrid from '../components/ContractInfoGrid'; 
import {ethers} from "ethers";
import { contractAddress } from '../constants/contract';

const pinataJWT = pinataKey;

const Admin: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const UploadFileToIPFS = async () => {
    if (!file) {
      console.log('No file selected');
      return;
    }

    console.log('Uploading file...');

    const formData = new FormData();
    formData.append('file', file);

    const pinataMetadata = JSON.stringify({
      name: file.name,
    });
    formData.append('pinataMetadata', pinataMetadata);

    const pinataOptions = JSON.stringify({
      cidVersion: 0,
    });
    formData.append('pinataOptions', pinataOptions);

    try {
      const res = await fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${pinataJWT}`,
        },
        body: formData,
      });

      const resData = await res.json();
      const url = `https://gateway.pinata.cloud/ipfs/${resData.IpfsHash}`;
      alert(url);
      console.log('File URI:', url);
    } catch (error) {
      alert('Error uploading file: ' + error);
      console.error('Error uploading file:', error);
    }
  };

  const loadWeb3 = async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      const ethereum = window.ethereum;
      try {
        // Request account access
        await ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(ethereum);
        setWeb3(web3Instance);

        // Get accounts
        const accs = await web3Instance.eth.getAccounts();
        setAccounts(accs);
        setIsConnected(true);

        console.log('Connected to MetaMask. Accounts:', accs);
      } catch (error) {
        console.error('Access denied to accounts:', error);
      }
    }
    // Legacy dapp browsers...
    else if ((window as any).web3) {
      const web3Instance = new Web3((window as any).web3.currentProvider);
      setWeb3(web3Instance);

      // Get accounts
      const accs = await web3Instance.eth.getAccounts();
      setAccounts(accs);
      setIsConnected(true);

      console.log('Connected to MetaMask (Legacy). Accounts:', accs);
    }
    // Non-dapp browsers...
    else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
  };

  const handleEnableSales = async () => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Enabling Sales...');

    var tokenId; 
    try{
      tokenId = await getTokenId();
      const currentAddress = await getCurrentAddress();

      console.log('address');
      console.log(currentAddress);

      if(typeof tokenId == "string"){
        try{
          const contractABI = MyTokenArtifact.abi;

          const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
          const tx = await nftContract.methods.setSaleAllowed(tokenId, true).send({ from: currentAddress });

          console.log(tx);
          console.log("Success!"); 
          alert("Transaction Completed Successfully"); 
        }catch(error){
          console.log(error);    
          alert(error);   
        }
      }

    }catch(error){
      console.log(error); 
    }
  };

  const getTokenId = async () => {
    console.log('getting token id: '); 
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    try {
      const currentAddress = await getCurrentAddress();

      console.log("Current address: ");
      console.log(currentAddress);

      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
      const tx: string | bigint = await nftContract.methods.getTokenId().call({ from: currentAddress });

      console.log("Token id: " + tx.toString());

      return tx.toString();

    } catch (error) {
      alert('Error : ' + error);
      console.error('Error :', error);
      return false; 
    }
  };

  const handleDisableSales = async () => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Enabling Sales...');
    
    var tokenId; 
    try{
      tokenId = await getTokenId();
      const currentAddress = await getCurrentAddress();

      console.log("Current address: ");
      console.log(currentAddress);

      if(typeof tokenId == "string"){
        try{
          const contractABI = MyTokenArtifact.abi;

          const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
          const tx = await nftContract.methods.setSaleAllowed(tokenId, false).send({ from: currentAddress });

          console.log(tx);
          console.log("Success!"); 
          alert("Transaction Completed Successfully"); 
        }catch(error){
          console.log(error);       
          alert(error);
        }
      }

    }catch(error){
      console.log(error); 
    }
  };

  const handleEnableTransfers = async () => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Enabling transfers');
    
    
    try{
      const currentAddress = await getCurrentAddress();
      
      console.log('address');
      console.log(currentAddress);

      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
      const tx = await nftContract.methods.updateTransfer(true).send({ from: accounts[0] });

      console.log(tx);
      console.log("Success!"); 
      alert("Transaction Completed Successfully"); 

    }catch(error){
      console.log(error); 
      alert(error); 
    }
  };

  const handleDisableTransfers = async () => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Disabling transfers');
    
    try{
      const currentAddress = await getCurrentAddress();
      
      console.log('address');
      console.log(currentAddress);

      const contractABI = MyTokenArtifact.abi;

      const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
      const tx = await nftContract.methods.updateTransfer(false).send({ from: accounts[0] });

      console.log(tx);
      console.log("Success!"); 
      alert("Transaction Completed Successfully"); 

    }catch(error){
      console.log(error); 
      alert(error); 
    }
  };

  const handleSetSalePrice = async (priceInWei: string) => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Sale Price Set:', priceInWei);
    console.log("Setting sale price...");

    var tokenId; 
    try{
      tokenId = await getTokenId();
      const currentAddress = await getCurrentAddress();

      console.log("current address");
      console.log(currentAddress);

      if(typeof tokenId == "string"){
        try{
          const contractABI = MyTokenArtifact.abi;

          const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
          const tx = await nftContract.methods.setSalePrice(tokenId, priceInWei).send({ from: currentAddress });

          console.log(tx);
          console.log("Success!"); 
          alert("Transaction Completed Successfully"); 
        }catch(error){
          console.log(error);    
          alert(error);   
        }
      }

    }catch(error){
      console.log(error); 
    }
  };

  const handleSetTokenURI = async (uri: string) => {
    if (!web3 || !isConnected) {
      alert('Web3 instance not initialized or not connected to MetaMask');
      console.error('Web3 instance not initialized or not connected to MetaMask.');
      return;
    }

    console.log('Setting Token URI');
    
    var tokenId; 
    try{
      tokenId = await getTokenId();
      const currentAddress = await getCurrentAddress();
    
      console.log('address');
      console.log(currentAddress);

      if(typeof tokenId == "string"){
        try{
          const contractABI = MyTokenArtifact.abi;

          const nftContract = new web3.eth.Contract(contractABI, contractAddress);
      
          const tx = await nftContract.methods.setTokenURI(tokenId, uri).send({ from: currentAddress });

          console.log(tx);
          console.log("Success!"); 
          alert("Transaction Completed Successfully"); 
        }catch(error){
          console.log(error);    
          alert(error);   
        }
      }

    }catch(error){
      console.log(error); 
    }
  };

  const getCurrentAddress = async (): Promise<string | undefined> => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        return accounts.length > 0 ? accounts[0] : undefined;
      } catch (error) {
        console.error('Error fetching accounts:', error);
        return undefined;
      }
    } else {
      console.log('MetaMask is not installed!');
      return undefined;
    }
  };
  
  return(
    // <div className="container">
    //   <h2>Admin Page</h2>
    //   <input type="file" onChange={handleFileChange} />
    //   <button onClick={UploadFileToIPFS}>Upload to IPFS</button>
    // </div>
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-lg w-full p-6 bg-white rounded-lg shadow-md">
        
        <h1 className="text-3xl font-semibold text-center mb-6">Admin Panel</h1>
        {isConnected ? (
          <AdminForm
          onEnableSales={handleEnableSales}
          onDisableSales={handleDisableSales}
          onEnableTransfers={handleEnableTransfers}
          onDisableTransfers={handleDisableTransfers}
          onSetSalePrice={handleSetSalePrice}
          onSetTokenURI={handleSetTokenURI}
        />
        ) : (
          <div className="text-center">
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={loadWeb3}
            >
              Connect to MetaMask
            </button>
          </div>
        )}
      </div>
    </div>
  )
};

export default Admin;

