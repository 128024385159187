import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import MyTokenArtifact from '../artifacts/contracts/ETHWhitepaper.sol/EthereumWhitepaperSignedByVitalikButerin.json'; 
import MetadataGrid from '../components/MetadataGrid'; 
import { contractAddress } from '../constants/contract';
import { pinataKey, pinataAPIKey } from '../constants/keys';

const Metadata: React.FC = () => {
    const [web3, setWeb3] = useState<Web3 | null>(null);
    const [accounts, setAccounts] = useState<string[]>([]);
    const [isConnected, setIsConnected] = useState(false);

    const [metadata, setMetadata] = useState<any>(null);

    const loadWeb3 = async () => {
        // Modern dapp browsers...
        if (window.ethereum) {
          const ethereum = window.ethereum;
          try {
            // Request account access
            await ethereum.request({ method: 'eth_requestAccounts' });
            const web3Instance = new Web3(ethereum);
            setWeb3(web3Instance);
    
            // Get accounts
            const accs = await web3Instance.eth.getAccounts();
            setAccounts(accs);
            setIsConnected(true);
            console.log('Connected to MetaMask. Accounts:', accs);
    
            await getTokenURI(web3Instance, accs[0]);
            
          } catch (error) {
            console.error('Access denied to accounts:', error);
          }
        }
        // Legacy dapp browsers...
        else if ((window as any).web3) {
          const web3Instance = new Web3((window as any).web3.currentProvider);
          setWeb3(web3Instance);
    
          // Get accounts
          const accs = await web3Instance.eth.getAccounts();
          setAccounts(accs);
          setIsConnected(true);
    
          console.log('Connected to MetaMask (Legacy). Accounts:', accs);

          await getTokenURI(web3Instance, accs[0]);
        }
        // Non-dapp browsers...
        else {
          console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
      };

    const getTokenURI = async (web3Instance: Web3, account: string) => {
        console.log('getting token URI: '); 
        try {
            const contractABI = MyTokenArtifact.abi;
    
            const nftContract = new web3Instance.eth.Contract(contractABI, contractAddress);
    
            //getting token id
            const tokenId: string | bigint = await nftContract.methods.getTokenId().call({ from: account });
          
            const ipfsUri: string = await nftContract.methods.tokenURI(tokenId.toString()).call({ from: account });
    
            const ipfsGatewayUrl = `https://gateway.pinata.cloud/ipfs/${ipfsUri.split("ipfs://")[1]}`;

            const response = await axios.get(ipfsGatewayUrl);
            
            const metadata = response.data;

            setMetadata(metadata); 
    
        } catch (error) {
          alert('Error : ' + error);
          console.error('Error :', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full w-[60%] p-6 bg-white rounded-lg shadow-md">
        
            <h1 className="text-3xl font-semibold text-center mb-6">Metadata</h1>
            {isConnected && metadata ? (
            <MetadataGrid
                name={metadata.name}
                description={metadata.description}
                image={metadata.image}
                animationUrl={metadata.animationUrl}
                attributes={metadata.attributes}
                creator={metadata.creator}
                provenance={metadata.provenance}
                />
            ) : (
            <div className="text-center">
                <button
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={loadWeb3}
                >
                Connect to MetaMask
                </button>
            </div>
            )}
        </div>
        </div>
    );
};

export default Metadata;



