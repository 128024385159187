import React from 'react';

interface ContractInfoProps {
  saleFeePercentage: String | null;
  salePrice: String | null; 
  tokenId: String | null; 
  contractAddress: String; 
  saleAllowed: Boolean; 
  onPurchase: () => void;
  tokenURI: String; 
}

const ContractInfoGrid: React.FC<ContractInfoProps> = ({ saleFeePercentage, salePrice, tokenId, contractAddress, saleAllowed, onPurchase, tokenURI }) => {
  return ( 
    <div>
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Contract Address:</span>
          <span className="block mt-2 break-words">{contractAddress}</span>
        </div>  
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Sale Fee Percentage:</span>
          <span className="block mt-2">{saleFeePercentage}</span>
        </div>
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Sale Price:</span>
          <span className="block mt-2">{salePrice} ETH</span>
        </div>
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Token ID:</span>
          <span className="block mt-2">{tokenId}</span>
        </div>
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Sale Allowed:</span>
          <span className="block mt-2">{saleAllowed ? 'Yes' : 'No'}</span>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4 mt-4">
        <div className="p-4 bg-gray-100 border border-gray-300 rounded">
          <span className="font-bold">Contract Metadata URI:</span>
          <span className="block mt-2 break-words">
          <a href={tokenURI.toString()} target="_blank" rel="noopener noreferrer">
            {tokenURI}
          </a>
          </span>
        </div>  
      </div>
      <div className="grid grid-cols-1">
        <button
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          onClick={onPurchase}
          disabled={!saleAllowed}
        >
          Purchase
        </button>
      </div>
    </div>
  );
};

export default ContractInfoGrid;
