import React, { useState } from 'react';
import Web3 from 'web3';

interface AdminFormProps {
  onEnableSales: () => void;
  onDisableSales: () => void;
  onEnableTransfers: () => void;
  onDisableTransfers: () => void;
  onSetSalePrice: (priceInWei: string) => void;
  onSetTokenURI: (uri: string) => void;
}

const AdminForm: React.FC<AdminFormProps> = ({ onEnableSales, onDisableSales, onEnableTransfers, onDisableTransfers, onSetSalePrice, onSetTokenURI }) => {
    const [salePrice, setSalePrice] = useState<string>('');
    const [tokenURI, setTokenURI] = useState<string>('');

    const handleSetSalePrice = () => {
        const web3 = new Web3();
        const priceInWei = web3.utils.toWei(salePrice, 'ether');
        onSetSalePrice(priceInWei);
      };

      const handleSetTokenURI = () => {
        const web3 = new Web3();
        const priceInWei = web3.utils.toWei(salePrice, 'ether');
        onSetTokenURI(tokenURI);
      };

      return (
        <div className="max-w-lg w-full p-6 bg-white">
          <div className="grid grid-cols-2 gap-4">
            <button
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              onClick={onEnableSales}
            >
              Enable Sales
            </button>
            <button
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
              onClick={onDisableSales}
            >
              Disable Sales
            </button>
            <button
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              onClick={onEnableTransfers}
            >
              Enable Transfers
            </button>
            <button
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
              onClick={onDisableTransfers}
            >
              Disable Transfers
            </button>
            <div className="mb-4 col-span-2">
              <input
                type="text"
                value={salePrice}
                onChange={(e) => setSalePrice(e.target.value)}
                placeholder="Enter sale price in ETH"
                className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              />
            </div>
            <div className="mb-4 col-span-2">
              <button
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
                onClick={handleSetSalePrice}
              >
                Set Sale Price
              </button>
            </div>
            <div className="mb-4 col-span-2">
              <input
                type="text"
                value={tokenURI}
                onChange={(e) => setTokenURI(e.target.value)}
                placeholder="Enter new URI (in ipfs://{CID} format)"
                className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              />
            </div>
            <div className="mb-4 col-span-2">
              <button
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
                onClick={handleSetTokenURI}
              >
                Set Token URI
              </button>
            </div>
          </div>
        </div>
      );
      ;
};

export default AdminForm;
